import React, { useState } from 'react';
import { Typography, withStyles, IconButton } from '@material-ui/core';
import { PictureViewStyles } from './styles';
import CloseIcon from '@material-ui/icons/Close';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Moment from 'moment';
import PropTypes from 'prop-types';

const MAX_LOAD_ATTEMPTS = 3;
const LOAD_TIMEOUT = 5000;

const PictureView = ({ classes, treatment, onCloseImage, changeTreatment, applyMarginToImage }) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    const [attempt, setAttempt] = useState(1);
    // const [imageError, setImageError] = useState(false);
    const [loadAttempts, setLoadAttempts] = useState(0);

    const imgSrc = (() => {
        if (treatment?.lhrCanvasImage) {
            return treatment.lhrCanvasImage;
        } else if (treatment?.canvasImage) {
            return treatment.canvasImage;
        }
    })();

    const handleImageLoad = () => {
        setImageLoaded(true);
        setAttempt(prev => prev + 1);
        // setImageError(false);
    };

    const handleImageError = () => {
        if (loadAttempts < MAX_LOAD_ATTEMPTS) {
            setTimeout(() => {
                setLoadAttempts(prev => prev + 1);
            }, LOAD_TIMEOUT);
            setImageLoaded(false);
            // setImageError(false);
        } else {
            setImageLoaded(false);
            // setImageError(true);
        }
    };

    // useEffect(() => {
    //     if (!imageLoaded && !imageError && loadAttempts > 0) {
    //         const timeout = setTimeout(() => {
    //             setLoadAttempts(prev => prev + 1);
    //         }, LOAD_TIMEOUT);
    //
    //         return () => clearTimeout(timeout);
    //     }
    // }, [imageLoaded, imageError, loadAttempts]);

    return (
        <div>
            <div className={classes.header}>
                <IconButton onClick={() => onCloseImage(treatment.id)}>
                    <CloseIcon />
                </IconButton>
            </div>
            <div className={classes.imageView} style={applyMarginToImage ? { marginTop: 52 } : {}}>
                <div className={classes.imageArea}>
                    {imgSrc && attempt && (
                        <img
                            alt="Treatment"
                            className={classes.canvasImage}
                            src={`${imgSrc}?retry=${loadAttempts}`}
                            onLoad={handleImageLoad}
                            onError={handleImageError}
                            style={imageLoaded ? {} : { display: 'none' }}
                        />
                    )}
                </div>
            </div>
            <div className={classes.spacedContainer}>
                <IconButton onClick={() => changeTreatment(treatment.id, true)}>
                    <NavigateBeforeIcon />
                </IconButton>
                <Typography className={classes.defaultFont}>
                    {treatment.createdAt && Moment(treatment.createdAt).format('DD/MM/YYYY HH:mm')}
                </Typography>
                <Typography className={classes.defaultFont}>{treatment.service?.name}</Typography>
                <Typography className={classes.defaultFont}>{treatment.practitioner?.displayName}</Typography>
                <IconButton onClick={() => changeTreatment(treatment.id)}>
                    <NavigateNextIcon />
                </IconButton>
                <span style={{ color: 'white' }}>{loadAttempts}</span>
            </div>
        </div>
    );
};

PictureView.propTypes = {
    classes: PropTypes.object.isRequired,
    treatment: PropTypes.object.isRequired,
    onCloseImage: PropTypes.func.isRequired,
    changeTreatment: PropTypes.func.isRequired,
    applyMarginToImage: PropTypes.bool
};

export default withStyles(PictureViewStyles)(PictureView);
