import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles, TextField, Select, MenuItem, InputLabel, FormControl, makeStyles } from '@material-ui/core';
import AutoCompleteStyles from './styles';
import { PropTypes } from 'prop-types';
import customDropdownSearch from '../../../../collums-components/helpers/customDropdownSearch';

const CustomAutoComplete = ({
    id,
    options,
    value,
    onChange,
    label = '',
    classes,
    getOptionLabel,
    getOptionValue,
    freeSolo = false,
    required,
    noCustomSearch = false,
    fullWidth = true,
    textfieldClasses = null,
    className = '',
    disabled,
    disabledNotGrayed,
    isSelect,
    getValue,
    placeholder
}) => {
    const useStyles = makeStyles({
        disabledSelect: {
            color: 'black !important'
        },
        disabledTextField: {
            '& .MuiOutlinedInput-root.Mui-disabled': {
                color: 'black !important'
            },
            '& .MuiInputBase-input.Mui-disabled': {
                color: 'black !important'
            },
            '& .MuiSvgIcon-root': {
                color: 'black !important'
            }
        }
    });

    const currentClasses = useStyles();

    if (isSelect) {
        const defaultGetFieldValue = value => value;
        const getOption = getOptionValue || defaultGetFieldValue;
        const getFieldValue = getValue || defaultGetFieldValue;
        const labelId = `${id}-label`;
        return (
            <FormControl className={`${classes.autocomplete} ${className}`}>
                <InputLabel id={labelId} className={`${classes.label}${!label ? ' no-label' : ''}`} disabled={disabled}>
                    {label}
                    {required ? ' *' : ''}
                </InputLabel>
                <Select
                    id={id}
                    value={getOption(value) || ''}
                    disabled={disabled || !options.length}
                    labelId={labelId}
                    className={`${classes.autocomplete} ${className}`}
                    onChange={e => {
                        onChange(e, getFieldValue(e.target.value));
                    }}
                    onClose={() => {
                        setTimeout(() => document.activeElement.blur(), 0);
                    }}
                    blurOnSelect
                    required={required}
                    variant="outlined"
                    classes={disabledNotGrayed ? { disabled: currentClasses.disabledSelect } : {}}
                >
                    {options.map((option, index) => {
                        return (
                            <MenuItem value={getOption(option)} key={`${getOptionLabel(option)}-${index}`}>
                                {getOptionLabel(option)}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        );
    }
    return (
        <Autocomplete
            id={id}
            className={`${classes.autocomplete} ${className}`}
            options={options || []}
            getOptionLabel={getOptionLabel}
            value={value}
            disabled={disabled}
            onChange={onChange}
            {...(freeSolo ? { onInputChange: onChange } : {})}
            freeSolo={freeSolo}
            blurOnSelect
            filterOptions={noCustomSearch ? undefined : customDropdownSearch}
            renderInput={params => (
                <TextField
                    {...params}
                    label={label}
                    required={required}
                    variant="outlined"
                    placeholder={placeholder || ''}
                    fullWidth={fullWidth}
                    className={disabled ? currentClasses.disabledTextField : ''}
                    {...(textfieldClasses && { className: textfieldClasses })}
                />
            )}
        />
    );
};

CustomAutoComplete.propTypes = {
    id: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    classes: PropTypes.object.isRequired,
    getOptionLabel: PropTypes.func.isRequired,
    freeSolo: PropTypes.bool,
    required: PropTypes.bool,
    noCustomSearch: PropTypes.bool,
    disabled: PropTypes.bool,
    disabledNotGrayed: PropTypes.bool,
    fullWidth: PropTypes.bool,
    className: PropTypes.string,
    textfieldClasses: PropTypes.string,
    isSelect: PropTypes.bool,
    getValue: PropTypes.func,
    getOptionValue: PropTypes.func,
    placeholder: PropTypes.string
};

export default withStyles(AutoCompleteStyles)(CustomAutoComplete);
